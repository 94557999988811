import React from "react";
import PageTitle from "./Components/PageTitle";

function ImpressumFragment() {
    return <div className={"p-4 bg-gray-600 text-gray-200 py-12 px-4 leading-loose"}>
        <div className={"m-auto max-w-4xl"}>
            <PageTitle>Impressum</PageTitle>
            <div className={"mt-8"}>
                Lukas Köll, BSc.<br/>
                Schwendergasse 59/19<br/>
                1150 Wien<br/>
                Email: koell.lukas@gmail.com<br/>
                UID: ATU73111857<br/>
                Österreichisches Gewerberegister GISA-Nr.: 31969451
            </div>
        </div>
    </div>
}

export default ImpressumFragment
