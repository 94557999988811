import {Provider} from "react-redux";
import store from "./store";
import ImpressumFragment from "./ImpressumFragment";
import {Route, Routes} from "react-router";
import {BrowserRouter, Link} from "react-router-dom";
import React from "react";
import {Reference} from "./Components/Reference";
import PageTitle from "./Components/PageTitle";
import img from './assets/bewerbungsfoto.jpg';
import {Ambulance} from "./References/Ambulance";

const HomeBanner = () => {
    return <div className={"h-auto py-4 bg-gray-900 font-text-regular"}>
        <div className={"max-w-2xl xl:max-w-4xl m-auto"}>

            {/*<p className={"text-white"}>*/}
            {/*    <h1 className={"text-xl"}>Vorausschauendes Programmieren</h1>*/}
            {/*    <p>*/}
            {/*        ENUMS: was f[r ein cancer srsly*/}
            {/*        also db zB: willst enum value adden bei grossen tables = downtime*/}
            {/*        enum values deleten = du bist gefucked wenn du persistente daten hast, die darauf verweisen*/}
            {/*        in php: zwar backed values, aber nur char, warum jesus es gibt nicht einmal einen native datentyp f. char den du gscheid typehinten kannst*/}
            {/*        in cpp+: somewhat decent*/}
            {/*        in js: vergiss es einfach, typescript generics machen nix nuetzliches weils nicht runterkompiliert*/}

            {/*        OOOODER benutzen wir sie einfach falsch:*/}
            {/*            enum = enumeration, also endliche aufzaehlung. technisch gesehen ist "einen value zu einer enum adden" = undefined*/}
            {/*                    technisch gesehen ist das, was man als "einen value zu einer enum adden" eeeeeigentlich: eine neue enum erzeugen, die den neuen value auch hat und dann alle alten enum-values auf die neue enum mappen*/}
            {/*            value, enum*/}

            {/*        IDS:*/}
            {/*            gib im zweifelsfall allem eine Unique generated ID*/}
            {/*            du glaubst sachen die dir in der echten welt ueber den weg laufen sind irgendwie unique, aber immer dann wenns doof ist sind sies dann doch nicht.*/}
            {/*            id, crdate (uuid!) sind ausser bei relation tables fast immer eine gute idee!*/}
            {/*       */}
            {/*       beim entwickeln: starte mit non-optional parameter/return values (ausser du weisst es scho natuerlich)*/}


            {/*    </p>*/}
            {/*</p>*/}
            <h3 className={"text-center font-text-normal text-3xl text-gray-400 p-4"}>
                Herzlich Willkommen in der
            </h3>
            <h3 className={"text-center text-5xl font-text-accent text-lime-500 p-4 leading-normal"}>
                Software-Schmiede
            </h3>
            <h3 className={"text-center text-4xl text-gray-400 p-4"}>BSc. Lukas Köll</h3>
            <div className={"p-2  m-1 "}><img src={img} className={"m-auto max-w-sm w-full p-1 border-2"}/></div>
            <div className={"m-auto px-3"}>
                <div
                    className={"max-w-sm w-auto m-auto text-black bg-gray-100 mb-12 p-2 italic text-center text-2xl font-text-accent"}>
                    <Link to={"/services"}><a href={"/services"}>feine Software nach Maß</a></Link>
                </div>
            </div>
            <div className={"text-center"}>
                <a href={"https://github.com/phpwutz"}>
                    <span className={"icon-github"}/>
                </a>
            </div>

            <h4 className={"font-text-regular text-gray-200 text-center text-2xl my-8 "}>Interest cloud:</h4>
            <ul className={"cloud text-gray-300 px-4 font-text-regular"}>
                <li data-weight={8}>react</li>
                <li data-weight={2}>next.js</li>
                <li data-weight={3}>javascript</li>
                <li data-weight={5}>hosting</li>
                <li data-weight={5}>sysops</li>
                <li data-weight={9}>linux</li>
                <li data-weight={7}>typescript</li>
                <li data-weight={9}>kubernetes</li>
                <li data-weight={4}>helm</li>
                <li data-weight={2}>kustomize</li>
                <li data-weight={9}>postgres</li>
                <li data-weight={2}>mysql</li>
                <li data-weight={3}>mariadb</li>
                <li data-weight={2}>mongodb</li>
                <li data-weight={7}>git</li>
                <li data-weight={5}>less</li>
                <li data-weight={9}>consulting</li>
                <li data-weight={5}>sass</li>
                <li data-weight={5}>css</li>
                <li data-weight={2}>wordpress</li>
                <li data-weight={8}>php</li>
                <li data-weight={2}>magento</li>
                <li data-weight={7}>symfony</li>
                <li data-weight={4}>java</li>
                <li data-weight={4}>python</li>
                <li data-weight={9}>c++</li>
                <li data-weight={3}>redis</li>
                <li data-weight={5}>rabbitMQ</li>
                <li data-weight={6}>elasticsearch</li>
                <li data-weight={2}>AWS</li>
                <li data-weight={2}>gitlab</li>
                <li data-weight={8}>git-ops</li>
                <li data-weight={4}>.net</li>
                <li data-weight={7}>c#</li>
            </ul>
        </div>
    </div>
}


const Page = ({children}) => {
    return <div className={"subpixel-antialiased"}>
        <div className={"text-xl font-text-regular"}>
            <div className={"bg-gray-200"}>
                {children}
            </div>
        </div>
        <ImpressumFragment/>
    </div>

}
const HomePage = () => {

    const QualificationItem = ({children}) => {
        return <li className={"px-4 lg:px-0 my-5 text-2xl list-check "}> ✔️ {children}</li>
    }

    return <div className={"subpixel-antialiased"}>
        <HomeBanner/>
        <div className={"text-xl font-text-regular"}>
            <div className={"bg-gray-200"}>
                {/*<div className={"bg-sky-900"}>*/}
                {/*    <div className={"px-4 lg:px-0 max-w-4xl m-auto  text-gray-100 py-12 text-3xl"}>*/}
                {/*        <PageTitle>This gun's for hire...</PageTitle>*/}
                {/*    </div>*/}
                {/*</div>*/}

                {/*<div className={"bg-slate-400 font-sans"}>*/}
                {/*    <div className={"px-4 lg:px-0  max-w-4xl m-auto py-12"}>*/}
                {/*        <p>Gerne unterstütze ich Sie oder Ihr Team im Rahmen von kurzfristigen Projekten oder*/}
                {/*            langfristigen Kooperationen.</p>*/}
                {/*    </div>*/}
                {/*</div>*/}

                <div className={"bg-amber-600 py-12  px-4 text-gray-100"}>
                    <div className={" max-w-4xl m-auto"}>
                        <PageTitle>Skills</PageTitle>
                    </div>
                </div>
                <div className={"bg-amber-100 py-12"}>
                    <div className={"max-w-4xl m-auto"}>
                        <ul className={""}>
                            <QualificationItem>> 10 Jahre Erfahrung als Softwareentwickler</QualificationItem>
                            <QualificationItem>Teamplayer</QualificationItem>
                            <QualificationItem>Kaufmännische Ausbildung Bundeshandelsakademie</QualificationItem>
                            <QualificationItem>Abgeschlossenes Studium der <a href={"https://informatics.tuwien.ac.at"}
                                                                              className={"underline"}> Informatik an der
                                TU Wien</a>, <a
                                href={"https://www.cg.tuwien.ac.at/research/publications/2014/lkoell-2014/"}
                                className={"underline"} target={"_blank"}>(zur Abschlussarbeit)</a>
                            </QualificationItem>
                            <QualificationItem>Englisch und deutsch verhandlungssicher in Schrift und
                                Sprache</QualificationItem>
                        </ul>
                    </div>
                </div>
                <div className={"p-4 text-gray-200  bg-gray-800"}>
                    <div className={"lg:max-w-4xl m-auto "}>
                        <div className={"text-4xl"}>Referenzen</div>

                        <div className={"flex flex-col"}>
                            <Reference url={"https://tschuus.at"}
                                       title={"Tschuus.at - Webshop für burgenländischen Traubensaft"}
                                       description={"Umsetzung eines angepassten WooCommerce Shops, seit dem Launch 2018 führe ich für Herrn Hötsch auch Hosting, regelmäßige Wartungsarbeiten und Anpassungen durch."}/>
                            <Reference url={"/references/ambulance"}
                                       target={"_self"}
                                       title={"Ambulance Westtirol - Massgeschneiderte Inhouse-Organisationssoftware"}
                                       description={"Anforderungserhebung, Konzeption, Entwurf & Umsetzung einer individuellen Organisationssoftware. Auch hier werden Wartungsarbeiten und Hosting von mir durchgeführt."}/>
                            <Reference url={"https://filousmusic.com"} title={"Filous Music"}
                                       description={"Entwicklung eines interaktiven digitalen Tonmischgeräts mit austauschbaren und individuell steuerbaren Spuren."}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/*<ContactForm />*/}
    </div>
}

function App() {
    return (
        <BrowserRouter>
            <Provider store={store}>
                <Page>
                    <Routes>
                        <Route path={"/"} element={<HomePage/>}/>
                        <Route path={"/references/ambulance"} element={<Ambulance/>}/>
                        {/*<Route path={"/portfolio"} element={<Portfolio/>}/>*/}
                        {/*<Route path={"/services"} element={<Leistungen/>}/>*/}
                    </Routes>
                </Page>

            </Provider>
        </BrowserRouter>
    );
}

export default App;
