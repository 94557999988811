import {createSlice} from "@reduxjs/toolkit";

const collapsibleMenubarSlice = createSlice({
    name: 'collapsibleMenuBar',
    initialState: {
        expanded: false,
    },
    reducers: {
        "setExpanded": (state, action) => {
            state.expanded = action.payload;

            if (typeof document !== 'undefined') {
                if (!action.payload) {
                    document.querySelector('body').classList.remove("scms-navigation-mobile-expanded");

                } else {
                    document.querySelector('body').classList.add("scms-navigation-mobile-expanded")
                }
            }
        }
    },
});
export const { expand, setExpanded } = collapsibleMenubarSlice.actions;

export default collapsibleMenubarSlice;
