import Carousel from 'react-bootstrap/Carousel';

import pdfImg from '../assets/referencegalleries/ambulance/custom-pdfs.jpg';
import appEditor from '../assets/referencegalleries/ambulance/appointmenteditor.png';

export const Ambulance = () =>{
    return <div className={"m-auto max-w-4xl text-black"}>
        <h2 className={"text-4xl py-4"}>Ambulance Westtirol </h2>
        <p>Anforderungserhebung, Konzeption, Entwurf & Umsetzung einer individuellen Organisationssoftware. Auch hier werden Wartungsarbeiten und Hosting von mir durchgeführt.</p>
        <img src={appEditor} />

    </div>
}